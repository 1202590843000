<template>
    <div id="termekek" class="products block--big position-relative">
        <div class="container-xl">                    
            <div class="row">                
                <div class="col-md-12 d-flex flex-column text-center">
                    <h2 class="font-header text-white text-uppercase text-center mb-5">
                        Részt vevő termékek
                    </h2>
                </div>
                <div class="col-12 col-lg-8 text-center offset-lg-2 mb-5">
                    <div class="mx-auto text-white">
                           <p>A játékban a <span class="fw-bold">125 g-os Toffifee termékek</span> vesznek részt, melyeket Magyarországon található kiskereskedelmi üzletben, vagy magánszemélyeket kiszolgáló online webáruházban vagy nagykereskedelmi áruházban vásároltak.</p>
                           <p class="fw-bold">Toffifee 125 g standard és Family Design Edition csomagolásban<br>Toffifee Coconut 125 g<br>Toffifee White Chocolate 125 g</p>
                    </div>
                </div>
                <div class="col-12 col-lg-6 pe-lg-0">
                    <img src="imgs/products/toff-01.png" alt="toffifee" class="img-fluid">
                     <img src="imgs/products/toff-03.png" alt="toffifee" class="img-fluid">
                    
                </div>
                 <div class="col-12 col-lg-6 ps-lg-0">
                   <img src="imgs/products/toff-02.png" alt="toffifee" class="img-fluid">
                    <img src="imgs/products/toff-04.png" alt="toffifee" class="img-fluid">
                </div>            
                <div class="col-12">
                    <div class="d-flex flex-column">
                        <p class="text-center text-secondary mt-7 fs-14" >A képek csak illusztrációk.</p>
                    </div>
                </div>  
            </div>         
        </div>
    </div>
</template>

<script>

export default {
    data() {
      return {
        settings: {
            arrows: true,
            dots: false,
            slidesToShow: 6,
            slidesToScroll: 1,
            initialSlide: 0,
            responsive: [{
                breakpoint: 1200,
                settings: {
                    slidesToShow: 6,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 2,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 530,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 380,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }]
        },
      }
    },
    methods: {   
        setGtm(btnName){
            this.GTtrackGA4({
                'event' : 'event',
                'category': 'jatekban_reszt_vevo_termekek',
                'action': 'gomb_kattintas',
                'label': btnName,                
                'clicked_text':btnName,
                'action_type': 'gomb_kattintas'
            })
        }
    }
}
</script>