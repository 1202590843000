<template>
    <div id="regisztracio" class="register block--big">
        <div class="container-xl pt-md-5">                        
            <div class="row" v-if="$store.state.promoStatus == 'live' ">                
                <!-- ITT KEZDŐDIK A FORM -->
                <ValidationObserver 
                    class="col-12 col-xl-10 offset-xl-1"
                    ref="observerReg" 
                    v-slot="{ handleSubmit }"
                    tag="div"
                    data-formname="registerForm"
                >
                    <div class="row">                
                        <div class="col-12 col-xl-8 offset-xl-2 mb-0 mb-md-2">
                            <h2 class="font-header text-white text-center mb-6 mb-sm-4 mt-0 fw-800 text-uppercase">Regisztráció</h2>
                            <p class="text-white fw-400 text-center mb-7 pb-xl-6">Nyertesség esetén személyes adatait személyi igazolványával és lakcímkártyájával kell tudni igazolnia. Az adatok megadása, így a játékban való részvétel önkéntes.</p>
                        </div>
                    </div>    
                        <form
                        class="form-inner-container form__inner" 
                        @submit.prevent="handleSubmit(onSubmit)"
                    >                
                        <div class="row ">
                            <!-- VEZETÉKNÉV -->
                            <!-- todo: regex:^[a-zA-Z\s]*$ -->
                            <div class="col-12 col-md-6">
                                <CustomInput
                                    label="Vezetéknév"
                                    name="last_name"
                                    placeholder="pl.: Molnár"
                                    tooltip="A játékos személyi igazolványába jegyzett vezetéknév."
                                    v-model="form.last_name"
                                    rules="required|min:2|customRegex"
                                />  
                            </div>
                            
                            <!-- KERESZTNÉV -->
                            <div class="col-12 col-md-6">
                                <CustomInput
                                    label="Keresztnév"
                                    name="first_name"
                                    placeholder="pl.: Kata"
                                    tooltip="A játékos személyi igazolványába jegyzett keresztnév."
                                    v-model="form.first_name"
                                    rules="required|min:2|customRegex"
                                />
                            </div>
                            
                            <!-- EMAIL -->
                            <div class="col-12 col-md-6">
                                <CustomInput
                                    label="E-mail cím"
                                    name="email"
                                    placeholder="pl.: molnarkata@gmail.com"
                                    tooltip="A játékos e-mail címe."
                                    v-model="form.email"
                                    rules="required|email"                                    
                                />
                            </div>
                            <!-- EMAIL MÉGEGYSZER -->
                             <div class="col-12 col-md-6">
                                <CustomInput
                                    label="E-mail cím még egyszer"
                                    name="emailConf"
                                    placeholder="pl.: molnarkata@gmail.com"
                                    tooltip="A játékos e-mail címe újra."
                                    v-model="form.emailConf"
                                    rules="required|email|confirmed:email"
                                />
                            </div>

                            <!-- JELSZÓ -->
                            <div class="col-12 col-md-6">
                                <CustomInput
                                    label="Jelszó"
                                    type="password"
                                    name="password"
                                    placeholder="Minimum 8 karakter"
                                    tooltip="Legalább 8 karakternek kell lennie és tartalmaznia kell legalább 1 speciális karaktert 1 nagy és 1 kisbetűt."
                                    v-model="form.password"
                                    rules="required|min:8|customPsw"
                                />
                            </div>

                            <!-- JELSZÓ MEGERŐSÍTÉS -->
                            <div class="col-12 col-md-6">
                                <CustomInput
                                    label="Jelszó még egyszer"
                                    type="password"
                                    name="passconf"
                                    placeholder="Minimum 8 karakter"
                                    tooltip="Jelszó még egyszer."
                                    v-model="form.passconf"
                                    rules="required|confirmed:password"
                                />
                            </div>

                            <!-- TELEFONSZÁM -->
                            <div class="col-12 col-md-6 phone-wrap">
                                <div class="form-label">Telefonszám</div>
                                <div class="d-flex w-100 align-items-start position-relative">
                                    <span class="me-2 d-block phone-prefix">+36</span>
                                    <CustomInput
                                        class="w-100"
                                        label=""
                                        name="phone"
                                        tooltip="Egy telefonszám, amelyen a játékos elérhető."
                                        v-model="phoneShort"                                        
                                        @input="formatPhoneNumber($event)"                                        
                                        rules="customPhone"
                                        placeholder="30-322-2111"
                                    />
                                </div>
                            </div>

                            <!-- KEDVENC ÁRUHÁZ -->
                            <!--<div class="col-12 col-md-6">
                                <div class="form-label">Kedvenc magyarországi Auchan áruház</div>
                                <DropDown
                                    label=""
                                    name="favourite_shop"
                                    placeholder="Válassz egyet!"
                                    :options="shops"
                                    @selectedItem="form.favourite_shop = $event"
                                />
                            </div>-->

                           <!-- <div class="col-12">
                                <div class="form-label">Melyik a kedvenc Hungaria pezsgőd?</div>
                                <DropDown
                                    label=""
                                    name="favourite_shop"
                                    placeholder="Válassz egyet!"
                                    :options="champaigns"
                                    @selectedItem="form.survey[0].answer = $event"
                                />
                            </div>-->
                        

                            <!-- JÁTÉKSZABÁLY ELFOGADÁSA -->
                            <div class="col-12">
                                <CheckBox
                                    class="mt-4"
                                    name="rules"
                                    v-model="form.adult"
                                    :checked="form.adult"
                                    rules="required|acceptConditions"                                  
                                >
                                    <p 
                                        class="mb-0 text-secondary"
                                    >
                                        Nyilatkozom, hogy betöltöttem a 18. életévemet.
                                        <span class="text-red fw-bold">*</span>
                                    </p>
                                </CheckBox>
                            </div>

                            <!-- ÁSZF ELFOGADÁSA -->
                            <div class="col-12">
                                <CheckBox
                                    class="mt-2"
                                    name="privacy"
                                    v-model="form.rules"
                                    :checked="form.rules"
                                    rules="required|acceptConditions"
                                >
                                    <p class="mb-0 text-secondary"> 
                                        <span>A</span>
                                        <span>&nbsp;</span>
                                        <a class="text-primary text-underline fw-700" :href="$store.state.file_url" target="_blank">Játékszabályzatot és az Adatkezelési tájékoztatót</a>
                                        <span>&nbsp;</span>
                                        <span>megismertem, az abban foglaltakat elfogadom.</span>
                                        <span class="text-red fw-bold">*</span>    
                                    </p>
                                </CheckBox>
                            </div>

                            <!-- SZEMÉLYES ADATOK -->
                            <div class="col-12">
                                <CheckBox
                                    class="mt-2"
                                    name="consent"
                                    v-model="form.consent"
                                    :checked="form.consent"  
                                >
                                    <p class="mb-0 text-secondary"> 
                                        Hozzájárulok, hogy nyertesként történő kisorsolásom esetén a Szervező a nevemet és lakóhelyemet, a nyerés tényét, valamint a nyeremény megjelölését közzé tegye a weboldalon.
                                    </p>
                                </CheckBox>
                            </div>

                            <!-- FELIRATKOZÁS A HÍRLEVÉLRE -->
                           <!--<div class="col-12">
                                <CheckBox
                                    class="mt-2"
                                    name="marketing"
                                    v-model="form.marketing"
                                    :checked="form.marketing"
                                    @input="changeMarketing()"
                                >
                                    <div>
                                        <p class="text-black">Feliratkozom az Auchan hírlevélre. Ezzel kijelentem, hogy megismertem és elfogadom az Auchan <a class="text-primary text-underline fw-700" href=" https://www.auchan.hu/adatvedelem" target="_blank">Adatkezelési tájékoztatóját</a>, előzetesen és önkéntesen hozzájárulok, hogy az Auchan Magyarország Kft. hírlevelet küldjön számomra, melyről bármikor egy kattintással leiratkozhatok.</p>
                                    </div>
                                </CheckBox>
                            </div>-->

                           <div class="mt-3 mb-md-4 text-sm-center d-flex">                                
                                    <span class="text-red fw-bold">*</span>                                    
                                    <p class="m-0 info-text text-black">A csillaggal jelölt mezők kitöltése kötelező.</p>
                            </div>
                            <div class="col-12 col-md-6" v-if="captcha_id">
                                <div v-if="captcha_img" class="mb-2 d-flex justify-content-start align-items-center">
                                    <div  style="border:1px solid white;border-radius:5px" class="inline-flex bg-white">
                                        <img v-if="captcha_img" :src="captcha_img" id="captchaImg" />
                                    </div>
                                    <div class="ms-2" style="width:20px; cursor:pointer" @click="setNewCaptcha()">
                                        <img src="@/assets/imgs/ic-arrows-rotate-solid.svg" title="Újat kérek" class="img-fluid" />
                                    </div>
                                </div>

                                <CustomInput
                                    label="Captcha"
                                    name="captcha"
                                    v-model="form.captcha"
                                    rules="required"
                                />
                                <div v-if="captcha_error">
                                    <span class="input-error">Hibás captcha</span>
                                </div>
                            </div>
                            <div v-if="form.marketing == 1" class="col-12">
                                <div class="p-6 subscribe mb-5 ">
                                    <h5 class="text-center text-primary mb-5 fw-700 fs-20">Köszönjük a hírlevél feliratkozási szándékodat, kérjük add meg ezt a két adatot:</h5>
                                    <div class="row">
                                    <!-- TITULUS -->
                                        <div class="col-12 col-md-6  mb-md-0">
                                            <DropDown
                                                label="Titulus"
                                                name="title"
                                                placeholder="Válassz..."
                                                :options="titles"
                                                @selectedItem="form.title = $event"
                                                rules="required"
                                            />
                                        </div>

                                        <!-- SZÜLETÉSI ÉV -->
                                        <div class="col-12 col-md-6">
                                            <SelectDate
                                                name="birth_date"
                                                label="Születési dátum" 
                                                placeholder="ÉÉ-HH-NN"
                                                @selectedDate="form.birth_date = dateTimeToSqlFormat($event)"
                                                rules="required"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>                            

                            <!-- SUBMIT --> 
                            
                            <div class="col-12 d-flex justify-content-center mt-md-5 mt-0">
                                <input 
                                    class="btn btn-secondary btn-submit" 
                                    type="submit" 
                                    value="Regisztráció"
                                    @click="checkErrors()"
                                >
                            </div>                            
                        </div>
                    </form>
                </ValidationObserver> <!-- ITT VÉGZŐDIK A FORM -->
            </div>              
            <div class="row" v-else>
                <div class="col-12 col-md-8 offset-md-2 ">
                    <h2 class="font-header fw-bold text-white text-center mb-4 pb-2 pb-md-5 mb-sm-2 mt-0 text-uppercase">Regisztráció</h2><!--
                    <p class="text-white text-center mb-8">Személyes adataid úgy add meg, hogy azokat személyi igazolványoddal és lakcímkártyáddal tudd igazolni, amennyiben nyerteseink között tudhatunk majd. Az adatok megadása, így a játékban való részvétel önkéntes.</p> -->
                    <!-- Before status -->
                    <div v-if="$store.state.promoStatus == 'before'" class="py-5 text-center form-inner-container form-inner-container-shadow form__inner ">
                        <img src="@/assets/imgs/ic-soon.svg" class="img-fluid mb-3" />
                        <div class="h5 mb-5 fw-700 text-secondary fs-24" >Köszönjük látogatását!</div>
                        <div class="text-secondary">A nyereményjáték még nem vette kezdetét, így jelenleg még nem lehetséges a kódfeltöltés. 
A promóció részleteivel kapcsolatosan azonban már tájékozódhat a weboldalon.</div>
                        <div class="h5 text-secondary fw-700 mt-4 fs-22">Várjuk vissza!</div>
                    </div>
                    <!-- after status -->
                    <div v-if="$store.state.promoStatus == 'after'" class="py-5 text-center form-inner-container form-inner-container-shadow form__inner">
                        <img src="@/assets/imgs/ic-sad.svg" class="img-fluid mb-4"/>
                        <div class="fw-700 fs-18 text-secondary">A nyereményjáték lezárult. Köszönjük az érdeklődést!</div>
                    </div>
                </div>
            </div>          
        </div>
        <!-- REGISTRATION RESULT MODAL -->
        <!-- <RegisterSuccessModal :email="sendedEmail" :isSuccessReg="regSuccess"/> -->
    </div>
    
</template>

<script>
/**
 * after the reg go to the reg result page
 */

import { ValidationObserver } from 'vee-validate'
import CustomInput from '@/components/base/CustomInput.vue'
import DropDown from '@/components/base/DropDown.vue'
import CheckBox from '@/components/base/CheckBox.vue'
import SelectDate from '@/components/base/SelectDate.vue'
// import RegisterSuccessModal from '@/components/RegisterSuccessModal.vue'
// import errorMessages from "@/setup/errorMsgs.json";
import {mask} from 'vue-the-mask'
import axios from 'axios'

export default {
    directives: {mask},
    components: {
        ValidationObserver,
        CustomInput,
        DropDown,
        CheckBox,
        SelectDate,
        // RegisterSuccessModal
    },
    data () {
        return {
            options: [],  
            champaigns:['Hungaria Extra Dry','Hungaria Rosé Extra Dry','Hungaria Irsai Olivér', 'Hungaria Grande Cuvée Brut','Hungaria Grande Cuvée Rouge','Hungaria Irsai Olivér Extra Dry'],         
            howOften:['Alkalmanként (félévente néhány alkalommal)', 'Csak a nyereményjátékban való részvétel kedvéért vásároltam','Rendszeresen (havonta)', 'Ritkán (évente egyszer, kétszer)' ],
            titles:['Nincs','Dr.', 'Prof.', 'Ifj.', 'Id.', 'Özv.'],
            years:['18-29', '30-39', '40-49', '50-59' ,'60+'],
            phoneShort: '',
            phoneRaw: '',
            captcha_img: null,
            captcha_id: null,
            isConditionAccepted: null,
            captcha_error: false,
            form: {
                captcha: null,
                first_name: '',
                last_name: '',
                email: '',
                emailConf:'',
                phone:'',
                password: '',
                passconf: '',
               // favourite_shop: '',
                adult: null,
                rules: null,
                consent: 0,
                marketing: 0,
               // title: '',
                birth_date:'',
                /*survey:[{
                    question_number:1,
                    answer:null
                }]*/                                           
           },
           /* surveyQuestions:[
               {
                   id:1,
                   question:"Hol találkoztál a nyereményjátékkal? (több válasz is adható)",
                   answers:[ 'Akciós újság', 'Áruházon belüli kommunikáció', 'Ismerős ajánlása', 'Egyéb']
               },
               {
                   id:2,
                   question: "Milyen gyakran vásárolsz Jana termékeket?",
                   answers:[]
               },
               {
                   id:3,
                   question: "Auchanon kívül hol vásárolsz ilyen terméket és miért?",
                   answers:[]
               },
                {
                   id:4,
                   question: "Milyen termékeknek örülnél a jövőben?",
                   answers:[]
               },
                {
                   id:4,
                   question: "Hány éves vagy?",
                   answers:[]
               }                  
           ],
           survey:[
                    {
                        question_number: 1,
                        answer: ""
                    },
                    {
                        question_number: 1,
                        answer: ""
                    },
                    {
                        question_number: 1,
                        answer: ""
                    },
                    {
                        question_number: 1,
                        answer: ""
                    },
                    {
                        question_number: 2,
                        answer: ""
                    },
                    {
                        question_number: 3,
                        answer: ""
                    },
                    {
                        question_number: 3,
                        answer: ""
                    },
                    {
                        question_number: 4,
                        answer: ""
                    },
                    {
                        question_number: 5,
                        answer: ""
                    }
                ] */            
           //regSuccess: false,
        //    sendedEmail: ""        
        }
    },
     watch: {
        form:{
            handler: function(){
                this.isConditionAccepted = this.form.rules
            },
            deep: true
        },
        isConditionAccepted: function(newValue) {
                if(newValue == 1){
                    this.captcha_error = false;
                    //get the capcha id
                    axios.get(process.env.VUE_APP_BASE_URL + '/api/captcha/get_new_id').then(response => {
                        this.captcha_id = response.data.id;
                    
                        //get the captcha img
                        axios.get(process.env.VUE_APP_BASE_URL + '/api/captcha/get_image?id='+this.captcha_id, {
                            responseType: 'blob',
                        }).then(response => {                          
                            this.showCapchaImg(response.data)
                            this.captcha_error = false;

                        }).catch(function (err) {
                            //NOT SUCCESS msg send
                            console.log(err);      
                            this.captcha_error = true;
                            
                        });
                    })
                }
            }            
        
    },
    computed:{
        shops(){
            return  this.$store.state.shops
        },
        formToSend()      {    
            // let _this = this        
            let data = this.form     
            // data.survey =   [{
            //         question_number: 1,
            //         answer: _this.form.survey[0].answer
            //     } ]      
            /* data.survey = [
                {
                    question_number: 1,
                    answer: this.survey[0].answer + ";" + this.survey[1].answer + ";" + this.survey[2].answer + ";" + this.survey[3].answer
                },
                {
                    question_number: 2,
                    answer: this.survey[4].answer
                },
                {
                    question_number: 3,
                    answer: this.survey[5].answer + ";" + this.survey[6].answer
                },
                {
                    question_number: 4,
                    answer: this.survey[7].answer
                },
                {
                    question_number: 5,
                    answer: this.survey[8].answer
                }
            ] */

            return data
        }
    },
    methods: {
        setNewCaptcha(){
            axios.get(process.env.VUE_APP_BASE_URL + '/api/captcha/get_new_id').then(response => {
                this.captcha_id = response.data.id;
            
                //get the captcha img
                axios.get(process.env.VUE_APP_BASE_URL + '/api/captcha/get_image?id='+this.captcha_id, {
                    responseType: 'blob',
                }).then(response => {                           
                    this.showCapchaImg(response.data) 
                    this.captcha_error = false;
                    
                }).catch(function (err) {
                    //NOT SUCCESS msg send
                    console.log(err);      
                    this.captcha_error = true;
                    
                });
            })
        },
        showCapchaImg(imgSource){                    
            const myImgUrl = URL.createObjectURL( imgSource );            
            this.captcha_img = myImgUrl                  
            
            // return new Promise( (res, rej) => { 
            //     captchaImg.onload = () => res(captchaImg);
            //     captchaImg.onerror = rej;
            // })        
        },
        changeMarketing(){
            if ( parseInt(this.form.marketing) === 0){
                this.form.birth_date = '',
                this.form.title = ''
            }
        },
        getItem(item){
            console.log(item)
            this.form.favorite_supermarket = item
        },
        getDate(date){
            console.log(date)
        },
        scrollToElement(element, diff){
            
            const offset = document.querySelector('.navbar').clientHeight + diff
            const bodyTop = document.body.getBoundingClientRect().top;
            const elementTop = element.getBoundingClientRect().top;
            const elementPos = elementTop - bodyTop;
            const offsetPos = elementPos - offset;

            window.scrollTo({
                top: offsetPos,
                behavior: 'smooth'
            });
        },
        onSubmit(){
            if (this.phoneShort){
                this.form.phone = '+36-' + this.phoneShort
            }

            var sendData = JSON.parse(JSON.stringify(this.formToSend));            
            sendData.captcha_id = this.captcha_id
            sendData.captcha = this.form.captcha  

            this.post('register',sendData).then((res)=>{
                if (res.data.status == true){                    
                    //this.regSuccess = true                                  

                    //go to the reg 
                    this.$router.push({name: 'successReg', params: {result: 'success'}})
                    
                    //reset form
                    this.setFormDefaultState()     
                    window.dataLayer.push({'event': 'form-submit', 'form-name': 'registration', 'form-submit': true, 'formerror': ''}); 
                    //this.setGtm('sikeres')                 
                }
                                
           }).catch((err)=>{                          
                //this.regSuccess = false   
               // this.setGtm('sikertelen')  
                                          
                this.goToError(err)                                                          
           })
      
        },
        analyzeErrors(err){
            return new Promise((resolve, reject) => {
                let errorsArray = Object.entries(err.response.data.error)
                for (const [errorName, errorValue] of errorsArray) {
                    // if (errorName == "email" && errorValue == "occupied" ){
                    //     this.$router.push({name: 'successReg', params: {result: 'not-success', details: 'occupied'}})
                    //     reject()
                    // } 
                    console.log(errorName, errorValue);
                    if(errorValue == 'invalid'){
                        window.dataLayer.push({'event': 'form-submit', 'form-name': 'registration', 'form-submit': false, 'form-error': 'invalid captcha'}); 
                        this.$router.push({name: 'successReg', params: {result: 'not-success', details: 'captcha'}})
                        reject()
                    }else if (errorName == 'maximum_registration_same_ip' && errorValue == 'limit_reached'){
                        window.dataLayer.push({'event': 'form-submit', 'form-name': 'registration', 'form-submit': false, 'form-error': 'limit reached'}); 
                        this.$router.push({name: 'successReg', params: {result: 'not-success', details: 'limit_reached'}})
                        reject()
                    } else{
                        resolve()
                    }
                }
            })
        },
        checkErrors(){
            let _this = this;
            setTimeout( function(){
                let firstError = document.querySelector('.register .input-error')
                if (firstError)                    
                    _this.scrollToElement(firstError, 80)
            }, 500)
        },
        goToError(err){            
            this.analyzeErrors(err).then(()=>{
                this.setErrorMsgs(err, 'observerReg').then(()=>{                
                    let firstError = document.querySelector('.register .input-error.active-error')
                    if (firstError)
                        this.scrollToElement(firstError, 80)       
                }).catch(()=>{
                    this.$router.push({name: 'successReg', params: {result: 'not-success'}})
                })   
            })
                                              
        },      
        setFormDefaultState(){
            this.form = {
                first_name: '',
                last_name: '',
                email: '',
                emailConf:'',
                phone:'',
                password: '',
                passconf: '',
               // favourite_shop: '',
                adult: null,
                rules: null,
                consent: null,
                captcha: null,
                marketing: 0,
              //  title: '',
                birth_date:'',
              /*  survey:{
                    question_number:1,
                    answer:null
                }*/
           }
            this.captcha_img = null,
            this.captcha_id = null,
            this.isConditionAccepted = null    
           this.phoneShort = '';
           this.phoneRaw = '';
           this.captcha_error = false;
        },
        setGtm(result){
            
            this.GTtrackGA4({
                'event' : 'event',
                'category': 'regisztracio',
                'action': 'gomb_kattintas',                                
                'clicked_text':'Regisztráció',
                'action_type': 'gomb_kattintas',
                'label': result,
                'success': result
            })
        
        }
    },
    created(){
        
    },
    mounted(){
        
    }
}
</script>