<template>
    <div class="py-5 faq" id="faq">
        <div class="container">
            <div class="row py-5" id="accordionExample">
                <div class="col-12 col-lg-8 offset-lg-2 text-center mb-4">
                    <h2 class="text-yellow">Gyakran ismételt kérdések</h2>   
                </div>
                <div class="col-12 col-lg-8 offset-lg-2 mb-4" v-for="(item, index) in questions" :key="index">
                    <button 
                    class="btn btn-collapse collapsed font-primary text-white fs-20"  
                    type="button" 
                    data-bs-toggle="collapse" 
                    :data-bs-target="'#collapse'+index" 
                    aria-expanded="false" 
                    :aria-controls="'#collapse'+index">
                        {{ item.question }}
                    </button>
                    <div class="collapse text-white" :id="'collapse'+index" aria-labelledby="headingOne" data-bs-parent="#accordionExample" v-html="item.answer">
                    </div>
                </div> 
            </div>      
        </div>
    </div>
</template>

<script>

export default {
    
  mounted() {

      this.$store.dispatch("getQuestions");

      setTimeout(() => {
        let loginLink = document.getElementById('faq_login_link')
        let applicationLink = document.getElementById('faq_submit_link')

        this.checkLoggedIn().then(() =>{                        
            loginLink.setAttribute('href', '')
        }).catch(() => {            
            applicationLink.setAttribute('href', '#regisztracio')
        })
      }, 100);
  },
  computed:{
    questions(){
      return this.$store.state.questions;
    },
  },
}
</script>