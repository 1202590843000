<template>
    <div id="bekuldeseim" class="my-sendings mb-5 pt-0">
        <div class="container-xl">
            
            <div class="row">
                <div class="col-12">
                    <h2 class="font-header text-white fw-800 text-uppercase text-center mb-3 mb-md-5 mt-4 mt-md-5">
                        Pályázataim
                    </h2>
                </div>
            </div>
            
            <div class="row" >                
                <div 
                    class="col-12 col-xxl-10 offset-xxl-1"
                >               
                    <div class="bg-white form-inner-container-small form-inner-container-shadow my-sendings__table p-0 overflow-hidden">
                        <!-- Sendings table -->
                        <div class="row d-none d-md-flex fw-700 align-items-center font-secondary bg-primary"
                        >
                            <div class="col-12 col-md-2 flex-shrink-0 flex-grow-0 my-sendings__table-th">
                                <p class="text-center m-0 py-4 text-white">AP Kód</p>
                            </div>
                            <div class="col-12 col-md-2 flex-shrink-0 flex-grow-0 my-sendings__table-th">
                                <p class="text-center m-0 py-4 text-white">NAV Kód</p>
                            </div>
                             <div class="col-12 col-md-2 flex-shrink-0 flex-grow-0 my-sendings__table-th">
                                <p class="text-center m-0 py-4 text-white">Számla azonosító</p>
                            </div>
                            <div class="col-12 col-md-2 flex-shrink-0 flex-grow-0 my-sendings__table-th">
                                <p class="text-center m-0 py-4 text-white">Vásárlás dátuma</p>
                            </div>
                            <div class="col-12 col-md-2 flex-shrink-0 flex-grow-0 my-sendings__table-th">
                                <p class="text-center m-0 py-4 text-white">Vásárlás időpontja</p>
                            </div>
                            <div class="col-12 col-md-2 flex-shrink-0 flex-grow-0 my-sendings__table-th">
                                <p class="text-center m-0 py-4 text-white">Beküldés dátuma</p>
                            </div>
                        </div>                    
                        <div v-if="sedings.length" class="bg-white" >
                            <div  
                                v-for="(item, idx) in sedings"
                                :key="'item' + idx"
                                class="row my-sendings__table-row text-secondary p-3 font-secondary align-items-center"
                            >
                                <div class="col-12 col-md-2">
                                    <p class="text-center m-0 py-2 fw-bold">{{ item.ap_code}}</p>
                                </div>
                                <div class="col-12 col-md-2">
                                    <p class="text-center m-0 py-2">{{ item.nav_code}}</p>
                                </div>
                                <div class="col-12 col-md-2">
                                    <p class="text-center m-0 py-2">{{ item.online_code}}</p>
                                </div>
                                <div class="col-12 col-md-2">
                                    <p class="text-center m-0 py-2">{{ item.buy_date}}</p>
                                </div>
                                <div class="col-12 col-md-2">
                                    <p class="text-center m-0 py-2">{{ item.buy_time}}</p>
                                </div>
                                <div class="col-12 col-md-2">
                                    <p class="text-center m-0 py-2">{{ item.send_day}}</p>
                                </div>
                            </div>
                        </div>
                        <div 
                            class="text-center my-4 bg-white"
                            v-else >
                            <!-- <img src="@/assets/imgs/no-send.svg" class="brand-img img-fluid" alt="Nincs pályázat"/> -->
                            <p class="text-dark-grey fs-14 mt-3 mb-0 fw-700">Még nincs feltöltött bizonylat</p>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
export default {
    data () {
        return {           
           
            sedings:[]
        }
    },
    props:{
        getMyNewSendings:{
            type: Boolean,
            required:false
        }
    },
    watch:{
        getMyNewSendings(){
            this.getMySendings()
        }
    },
    methods:{
        getMySendings(){
            this.get('profile/applicants',{})
                .then((resp) => {
                    this.sedings = resp.data.applicants                    
                }).catch((err) => {
                    console.log(err)
                })
        }
    },
    created(){
        this.getMySendings()
    }
}
</script>