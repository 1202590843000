<template>
    <div id="nyertesek" class="winners block--big">
        <div class="winners--custom-bg">
            <h2 class="text-center mt-0 text-uppercase text-white mb-5">
                Sorsolás és nyertesek
            </h2>
            <div class="container-xl">
                <div class="row">                
                    <div class="col-12 col-xl-10 offset-xl-1 mb-0 mb-md-5 text-center order-1">
                        <div class="m-0 winners__text text-secondary bg-white p-4" style="border-radius: 20px;">
                            <p> 
                                <span class="text-uppercase fw-bold font-primary">Heti nyeremények sorsolása</span><br><br>
                                <a @click="goToGyik" v-scroll-to="{ el: '#collapse8', offset: -200 }" class="text-primary">A heti nyeremények</a> kiosztása előre kisorsolt nyerő időpontok alapján történik, az adott nyerő időpontban vagy az azt követő első érvényes beküldést végrehajtó játékos nyeri meg az adott heti nyereményt. A heti nyeremények nyerteseit az adott játékhetet követő hétfőn, ünnepnap esetében az első munkanapon* értesítjük a Lebonyolító által küldött e-mail üzenetben.
                            </p>
                            <p class="d-flex flex-wrap justify-content-center align-items-center">*<span>2024. április 22., </span>&nbsp;<span> 2024. április 29., </span>&nbsp;<span> 2024. május 6., </span>&nbsp;<span> 2024. május 13., </span>&nbsp;<span> 2024. május 21., </span>&nbsp;<span>  2024. május 27., </span>&nbsp;<span> 2024. június 3.</span>
                            </p>
                        </div>
                        <p class="m-0 winners__text text-secondary bg-white p-4 mt-5" style="border-radius: 20px;"> 
                            <span class="text-uppercase fw-bold font-primary">Fődíj sorsolása</span><br><br>
                            <strong> <a @click="goToGyik" v-scroll-to="{ el: '#collapse8', offset: -200 }" class="text-primary">A fődíj sorsolására</a> 2024. június 5-én 14:00 órakor kerül sor.</strong> A sorsolás helyszíne: A4C Marketing Kft. székhelye (1092 Budapest, Bakáts tér 2., bejárat a Bakáts utca 5-7. felől)<br>A fődíj nyertesét a sorsolást követően értesítjük a Lebonyolító által küldött e-mail üzenetben.
                        </p>
                    </div>                                                
                </div>
            </div>
            <div class="winners__winning-wrap">
                <div class="container-xl">
                    <div class="row fodij__container">
                        <div class="col-6 col-md-4 col-lg-3 text-start position-relative">
                            <img src="imgs/winnings/fodij.png" class="img-fluid fodij">
                        </div>
                        <div class="col-6 col-md-4 offset-md-2 col-lg-3 offset-lg-6 text-end">
                            <img src="imgs/winnings/heti.png" class="img-fluid">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="bg-primary">
            <div class="container-xl block--big py-5" style="margin-top: -1px;">
                <div class="row pt-5">     
                    <div class="col-12 col-lg-10 col-xl-8 offset-lg-1 offset-xl-2">
                        <!-- TAB -->
                        <div class="winners-button-select mb-5" >
                            <!--<button 
                                class="btn text-primary fw-700 " 
                                :style="{'background: rgba(255, 255, 255, 0.6)':winnerState === 'napi'}" 
                                v-on:click="setWinnerState('napi')"
                            >
                                Napi <span class="d-none d-md-block ms-1"> nyertesek</span>
                            </button>-->
                            <button 
                                class="btn text-white fw-700" 
                                :style="winnerState == 'heti' ? 'background: #FFDE1F;color:#5F1700!important;' : ''" 
                                v-on:click="setWinnerState('heti')"
                            >
                                Heti <span class="d-none d-md-block ms-1"> nyertesek</span>
                            </button> 
                            <button 
                                class="btn fw-700 text-white"
                                :style="winnerState == 'fodij' ? 'background: #FFDE1F;color:#5F1700!important;':''" 
                                v-on:click="setWinnerState('fodij')"
                            >
                                Fődíj <span class="d-none d-md-block ms-1">nyertese</span>
                            </button>
                        </div>
                        <!-- TABLE -->
                        <div class="winners__border mb-5">
                            <div class="winners__table-wrap winners__table-wrap--heading">                        
                                <table cellspacing="0" cellpadding="0" border="0"  class="winners__block w-100 custom-table"  >
                                    <thead>
                                        <tr class="winners-top font-primary" >
                                            <th class="w-50 text-white">Nyertes neve</th>
                                            <th class="w-50 text-white">Város</th>                            
                                        </tr>
                                    </thead>
                                </table>
                            </div>
                            <div class="winners__table-wrap winners__table-wrap--bg text-dark">                        
                                <table cellspacing="0" cellpadding="0" border="0"  class="winners__block w-100 custom-table"  :class="[`winner-table_${winnerState}`]" >
                                    <tbody v-if="filteredWinners && filteredWinners.length && loginShow">                          
                                        <tr class="winners-list fs-14 text-white" v-for="(winner, winnerindex) in filteredWinners" :key="winnerindex">
                                            <td class="winners-id fw-700 text-secondary w-50 p-3" style="background-color: rgba(255, 255, 255, 0.3);">
                                                <div>{{ winner.name }}</div>                                     
                                            </td>                          
                                            <td class="winners-product text-secondary text-right text-md-left w-50 p-3" style="background-color: rgba(255, 255, 255, 0.3);">
                                                <span>{{winner.winning_time}}</span>
                                            </td>
                                        </tr>                            
                                    </tbody>
                                    <tbody v-else>
                                        <tr v-if="loginShow">
                                            <td v-if="winnerState != 'fodij'" class="no-winner text-black text-center py-3" colspan="2">
                                                Hamarosan kiderül, kik lettek a nyertesek
                                            </td>
                                            <td v-if="winnerState == 'fodij'" class="no-winner text-black text-center py-3" colspan="2">
                                                Hamarosan kiderül, ki lett a fődíj nyertese
                                            </td>                                      
                                        </tr>
                                        <tr v-else>
                                            <td class="no-winner text-center py-3" colspan="2">
                                                A kisorsolt nyertesek részére a nyeremények átadása megtörtént!
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>                        
                            </div>

                            
                        </div>

                        <div class="d-flex justify-content-center py-4" v-if="needMoreBtn">
                                <button target="_blank" type="button" class="btn btn-primary" v-on:click="moreWinners(true)">Mutasd a további nyerteseket</button>
                        </div>
                    </div>           
                    
                </div> 
            </div> 
        </div>
    </div>
</template>


<script>

export default {
    data () {
        return {
            winnersAll:[],
            winnerState:'heti',
            needMoreBtn: false,
            needMoreWinners: false
        }
    },
    computed:{
        filteredWinners() {
            let result = []
            //let _this = this
        
            if(this.winnersAll ?? []){                
                
                result = this.winnersAll.filter(w => w.type === this.winnerState)

               /* _this.winnersAll.forEach(w => {
                    if (_this.winnerState == 'napi' || _this.winnerState == 'fodij'){
                        if (w.type === this.winnerState){
                            result.push(w)
                        }
                    } else {
                        if (_this.winnerState == 'heti'){
                            if (w.type != 'napi' && w.type != 'fodij'){                            
                                result.push(w)
                            }
                        }
                    }

                })*/

                if(result.length > 10 && !this.needMoreWinners){
                    this.checkBtn(true);
                    return result.slice(0,10);
                }else{
                    this.checkBtn(false);
                    return result;
                }
            }
            this.checkBtn(false);
            return result;
        }
    },
    methods: {
        goToGyik(){
            document.getElementById("collapse8").classList.add("show"); 
        },
        setWinnerState(newState) {
            this.moreWinners(false); 
            this.winnerState = newState;      
        },
        checkBtn(newState) {
            this.needMoreBtn = newState;
        },
        moreWinners(newState){
            this.needMoreWinners = newState;
        },
        selectWinners(){
            let _this = this
            this.wins.forEach( item => {                
                let winTypeWinners = _this.winnersAll.filter( element => element.type == item.type )
                //console.log('winTypeWinners',winTypeWinners)
                if (winTypeWinners)
                    item.winners = winTypeWinners
            })
        }
    },
    created(){
        this.get('info/winners',{})
        .then((resp) => {
          this.winnersAll = resp.data.winners
          //this.selectWinners()
        }).catch((err) => {
            console.log(err)
        })
    }
    
}
</script>