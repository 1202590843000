<template>    
    <div class="hero__outer">
        <div class="hero hero__inner" id="hero">        
            <div class="hero-container">
                <img src="@/assets/imgs/hero-bg.jpg" class="img-fluid d-none d-lg-block" />
                <img src="@/assets/imgs/hero-bg-tablet.jpg" class="img-fluid d-none d-sm-block d-lg-none" />
                <img src="@/assets/imgs/hero-bg-mobile.jpg" class="img-fluid d-block d-sm-none" />
            </div>
        </div>
        <div class="text-center fs-14 text-secondary py-4 bg-yellow hero-text">                                
            A képek csak illusztrációk.<br>*A nyeremények kizárólag a Fővárosi Állat- és Növénykertben használhatók fel.<br>A vásárlást igazoló eredeti bizonylatot sértetlen állapotban <strong>2024. július 31</strong>-ig meg kell őrizni.  <br/>
            
        </div>  
        <div class="bg-primary">                                
            <div class="text-center pt-5">
                <p class="text-secondary fs-18 mb-0">A promóció időtartama:</p>
                <p class="hero__date-wrap text-secondary text-uppercase font-primary fw-700 mb-0" >2024. április 15. - június 2.</p>
                <div class="text-center text-dark mt-4 mx-2 mx-lg-0">
                    <button v-if="$store.state.promoStatus != 'before'"
                        class="btn btn-primary mb-3 order-md-2 me-md-2 md-btn"
                        v-scroll-to="{el:!$store.state.user ? '#regisztracio' : '#palyazat',  offset: -80}"
                        
                    >
                        Kódfeltöltés
                    </button>
                    <a class="btn btn-primary--outline me-0 ms-lg-2 order-md-1 mb-3 md-btn" :href="$store.state.file_url" target="_blank">
                        Játékszabályzat
                    </a>   
                </div>                                      
            </div>
        </div>  
    </div>            
</template>

<script>

//import Ribbon from '@/components/Ribbon.vue'

export default {
    components:{
        //Ribbon
    },
    methods: {      
        setGtm(btnName){
            this.GTtrackGA4({
                'event' : 'event',
                'category': 'jatekleiras',
                'action': 'gomb_kattintas',
                'label': btnName,                
                'clicked_text':btnName,
                'action_type': 'gomb_kattintas'
            })
        }
    }
}
</script>