<template>
    <div id="kapcsolat" class="contact block--big">
        <div class="container-xl">                                
            <div class="row pt-5">   
                
                <div class="col-12 col-md-10 offset-md-1">
                    <div class="form-inner-container">                                                  
                        <!-- ITT KEZDŐDIK A FORM --> 
                        <ValidationObserver 
                            class="col-12 col-xl-10 offset-xl-1"
                            ref="observerCont" 
                            v-slot="{ handleSubmit }"
                            v-if="!formSended"
                            data-formname="contactForm"
                        >
                            <h2 class="font-header text-secondary text-uppercase text-center mb-5">Kapcsolat</h2>
                            <div class="row" v-if="!formSended">                    
                                <div class="col-12 pb-7">                           
                                    <p class="text-center mb-0 text-secondary mb-3 ">
                                       Kérdése van? Írjon nekünk! A Játék ideje alatt, illetve <strong>2024. július 31-ig</strong> információs e-mail címet üzemeltetünk, melyen a megküldött megkeresések munkanapokon kerülnek megválaszolásra.<br>Kérdéseit az <span><a :href="'mailto:'+$api_conf.emailAddress" class="text-underline text-primary fw-700" target="_blank"><span v-html="$api_conf.emailAddress"></span></a></span> címen vagy az alábbi űrlapon keresztül várjuk!</p>
                                </div>
                            </div>   
                            <form 
                                class=""
                                @submit.prevent="handleSubmit(onSubmit)"
                            >
                            
                                <div class="row">
                                    <div class="col-12 col-md-6">
                                        <CustomInput
                                            label="Vezetéknév"
                                            name="last_name"
                                            placeholder="pl.: Molnár"
                                            tooltip="A játékos személyi igazolványába jegyzett vezetéknév."
                                            v-model="form.last_name"
                                            rules="required|min:2|customRegex"
                                        />
                                    </div>
                                    
                                    <div class="col-12 col-md-6">
                                        <CustomInput
                                            label="Keresztnév"
                                            name="first_name"
                                            placeholder="pl.: Kata"
                                            tooltip="A játékos személyi igazolványába jegyzett keresztnév."
                                            v-model="form.first_name"
                                            rules="required|min:2|customRegex"
                                        />
                                    </div>

                                    <div class="col-12 col-md-6">
                                        <CustomInput
                                            label="E-mail cím"
                                            name="email"
                                            placeholder="pl.: molnarkata@gmail.com"
                                            tooltip="A játékos e-mail címe."
                                            v-model="form.email"
                                            rules="required|email"
                                        />
                                    </div>
                                    
                                    <!-- TELEFONSZÁM -->
                                    <div class="col-12 col-md-6 phone-wrap">
                                        <div class="form-label">Telefonszám</div>
                                        <div class="d-flex w-100 align-items-start position-relative">
                                            <span class="me-2 d-block phone-prefix">+36</span>
                                            <CustomInput
                                                class="w-100"
                                                label=""
                                                name="phone"
                                                placeholder="30-322-2111"
                                                tooltip="Egy telefonszám, amelyen a játékos elérhető."
                                                v-model="phoneShort"                                        
                                                @input="formatPhoneNumber($event)"                                        
                                                rules="customPhone"
                                            />
                                        </div>
                                    </div>

                                    <div class="col-12">
                                        <CustomText
                                            label="Üzenet"
                                            name="message"
                                            v-model="form.message"
                                            rules="required"
                                            :placeholder="'Írd ide az üzenetet...'"
                                        />
                                    </div>

                                    <div class="col-12">
                                        <CheckBox
                                            class="my-2"
                                            name="condition"
                                            v-model="form.condition"
                                            :checked="form.condition"
                                            rules="required|acceptConditions"
                                        >
                                        <p class="font-secondary text-black mb-0">
                                            <span>
                                                Hozzájárulok, hogy megadott adataimat a Kapcsolatfelvétel során az adatkezelő kezelje. Bővebb információ az
                                            </span>
                                            <a 
                                                :href="$store.state.privacy_url"
                                                target="_blank"
                                                class="text-primary fw-700 text-decoration-underline"
                                            >
                                                Adatkezelési tájékoztatóban.
                                            </a>
                                            <span class="fw-bold text-red"> *</span>
                                            </p>
                                        </CheckBox>
                                    </div>
                                    
                                    <div class="row">
                                        <div class="col-12 d-flex">
                                            <p class="text-primary text-center mt-5 mb-4">
                                                <span class="fw-bold text-red">*</span>
                                                <span>&nbsp;</span>
                                                <span class="text-black fs-14" >A csillaggal jelölt mezők kitöltése kötelező.</span>
                                            </p>
                                        </div>
                                        <div class="col-12 col-md-6" v-if="captcha_id">
                                            <div v-if="captcha_img" class="mb-2 d-flex justify-content-start align-items-center">
                                                <div  style="border:1px solid white;border-radius:5px" class="inline-flex bg-white">
                                                    <img v-if="captcha_img" :src="captcha_img" id="captchaImg" />
                                                </div>
                                                <div class="ms-2" style="width:20px; cursor:pointer" @click="setNewCaptcha()">
                                                    <img src="@/assets/imgs/ic-arrows-rotate-solid.svg" title="Újat kérek" class="img-fluid" />
                                                </div>
                                            </div>
                                            <CustomInput
                                                label="Captcha"
                                                name="captcha"
                                                v-model="form.captcha"
                                                rules="required"
                                            />
                                            <div v-if="captcha_error">
                                                <span class="input-error">Hibás captcha</span>
                                            </div>
                                        </div>
                                    </div>                                    
                                </div>        
                                <div class="row" v-if="loginShow">
                                    <div class="col-12 d-flex justify-content-center my-2 my-sm-4">
                                        <input 
                                            class="btn btn-secondary btn-submit" 
                                            type="submit" 
                                            value="Üzenet küldése"                                            
                                        >
                                    </div>
                                </div>    
                            </form>
                        </ValidationObserver>

                        <!-- Visszajelző screen -->
                        <div v-if="formSended" class="text-center">
                            <h4 class="fw-700 text-primary mb-5 fs-30 fs-md-46 text-uppercase">Kapcsolat</h4>
                            <img v-if="formSubmitStatus != 'not-success'" src="@/assets/imgs/ic-success-msg.svg" class="img-fluid"/> 
                            <p class="p-4 fw-700 text-center text-secondary" :class="[{'contact-success': formSubmitStatus == 'success'},{'contact-warning': formSubmitStatus != 'success' }]" v-html="formSubmitResult"></p>
                            <div class="text-center mt-4">
                                <button 
                                    class="btn btn-secondary"    
                                    @click="goBack()"                            
                                >
                                    Új üzenet küldése
                                </button>
                            </div>
                        </div>                           
                    </div>
                </div>    
            </div>      
        </div>                
    </div>        
</template>

<script>
import CustomInput from '@/components/base/CustomInput.vue'
import CustomText from '@/components/base/CustomText.vue'
import CheckBox from '@/components/base/CheckBox.vue'
import { ValidationObserver } from 'vee-validate'
import {mask} from 'vue-the-mask'
import axios from 'axios'

export default {
    directives: {mask},
    components: {
        ValidationObserver,
        CustomInput,
        CustomText,
        CheckBox
    },
    data () {
        return {
            form: {
                first_name: '',
                last_name: '',
                email: '',
                phone: '',
                message: '',
                condition: null,
                captcha: null
            }, 
            phoneRaw: '',
            phoneShort: '',
            successFormSending: false,
            formSubmitted: false,
            formSubmitResult: "",
            formSubmitStatus: "",
            formSended : false,
            captcha_img: null,
            captcha_id: null,
            isConditionAccepted: null,
            captcha_error: false
        }
    },
    watch: {
        form:{
            handler: function(){
                this.isConditionAccepted = this.form.condition
            },
            deep: true
        },
        isConditionAccepted: function(newValue) {
                if(newValue == 1){
                    this.captcha_error = false;

                    //get the capcha id
                    axios.get(process.env.VUE_APP_BASE_URL + '/api/captcha/get_new_id').then(response => {
                        this.captcha_id = response.data.id;
                    
                        //get the captcha img
                        axios.get(process.env.VUE_APP_BASE_URL + '/api/captcha/get_image?id='+this.captcha_id, {
                            responseType: 'blob',
                        }).then(response => {                           
                            this.showCapchaImg(response.data) 
                            this.captcha_error = false;
                            
                        }).catch(function (err) {
                            //NOT SUCCESS msg send
                            console.log(err);      
                            this.captcha_error = true;
                            
                        });
                    })
                }
            }            
        
    },
    methods: {
        defaultFormState(){
            this.form = {
                first_name: '',
                last_name: '',
                email: '',
                phone: '',
                message: '',
                condition: null,
                captcha: null,
            }                  
            this.captcha_img= null
            this.phoneShort = '';          
            this.phoneRaw = '' ; 
            this.captcha_img = null,
            this.captcha_id = null,
            this.isConditionAccepted = null,
            this.captcha_error = false                       
        },
        goBack(){
            this.formSubmitted = false     
            this.formSended = false
            this.formSubmitResult= ""
            this.formSubmitStatus = ""   
            this.defaultFormState()   
        },
        onSubmit(){
            var _this = this;            

            if (_this.phoneShort){
                _this.form.phone = '+36-' + _this.phoneShort
            }

            if (!_this.formSubmitted) {
                _this.formSubmitted = true

                var sendData = JSON.parse(JSON.stringify(this.form));        
                sendData.captcha_id = this.captcha_id
                sendData.captcha = this.form.captcha    
                
                //TESZT: hibaüzi előhozása - a this.post sort kommenteld ki, és ezt hívd be helyette                
                this.post('message', sendData)
                .then((response) => {                   
                    //SUCCESS message send
                    if (response.data.status === true) {                        
                        // console.log('sikeres üzenet küldés')
                        _this.formSended = true
                        _this.formSubmitStatus = 'success'
                        _this.formSubmitResult= "<span >Köszönjük az üzenetet!</span><br>Az űrlap sikeresen elküldve. Hamarosan válaszolunk!"
                        _this.defaultFormState()   
                        window.dataLayer.push({'event': 'form-submit', 'form-name': 'contact', 'form-submit': true, 'formerror': ''});
                       // _this.setGtm('sikeres')        
                    } 
                    _this.formSubmitted = false // to avoid the double form submit                    

                }).catch(function (err) {
                    //NOT SUCCESS msg send
                    console.log(err);                    
                    _this.goToError(err)  
                    this.setNewCaptcha();   
                    //_this.setGtm('sikertelen')  
                    _this.formSubmitted = false // to avoid the double form submit                    
                });
                let element = document.querySelector('#kapcsolat')
                this.scrollToElement(element, -80)
            } else {
                console.log('double form submit')
            }

        },
        goToError(err){            
            
          this.setErrorMsgs(err, 'observerCont').then(()=>{                
              let firstError = document.querySelector('.contact .input-error')
              this.scrollToElement(firstError, 80)       
          }).catch(()=>{
              //the error not bound to a field
            this.formSended = true  
            window.dataLayer.push({'event': 'form-submit', 'form-name': 'contact', 'form-submit': false, 'formerror': 'Error sending message'});                                                  
                    
            this.formSubmitStatus = 'not-success'
            this.formSubmitResult= "<p class='input-error'>Hiba az üzenetküldés során, kérjük, próbálja meg újra.</p>"
          })                                   
        },
        setNewCaptcha(){
            this.form.captcha = "";
            this.captcha_error  = false;
            axios.get(process.env.VUE_APP_BASE_URL + '/api/captcha/get_new_id').then(response => {
                this.captcha_id = response.data.id;
            
                //get the captcha img
                axios.get(process.env.VUE_APP_BASE_URL + '/api/captcha/get_image?id='+this.captcha_id, {
                    responseType: 'blob',
                }).then(response => {                           
                    this.showCapchaImg(response.data) 
                    this.captcha_error = false;
                    
                }).catch(function (err) {
                    //NOT SUCCESS msg send
                    console.log(err);      
                    this.captcha_error = true;
                    
                });
            })
        },
        showCapchaImg(imgSource){                    
            const myImgUrl = URL.createObjectURL( imgSource );            
            this.captcha_img = myImgUrl                  
            
            // return new Promise( (res, rej) => { 
            //     captchaImg.onload = () => res(captchaImg);
            //     captchaImg.onerror = rej;
            // })        
        },
        setGtm(result){
            this.GTtrackGA4({
                'event' : 'event',
                'category': 'kapcsolat',
                'action': 'kuldes_gomb_kattintas',
                'label': result,
                'button': 'kuldes',                
                'clicked_text':'Üzenetet küldök',
                'action_type': 'gomb_kattintas',
                'success': result
            })
        }
    }
}
</script>