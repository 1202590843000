<template>
    <div id="jatekszabaly" class="rules position-relative block--big">
        <div class="container-xl">

            <div class="row px-2">
                <h2 class="text-secondary text-center mb-5 mb-md-5">
                    A játék lépései
                </h2>
                <div class="col-12  col-lg-10 offset-lg-1 bg-white rules-container">
                    <!-- desctop -->
                    <ul 
                        class="rules-buttons nav nav-pills justify-content-center d-none d-sm-flex w-100 pt-4" 
                        id="pills-tab" 
                        role="tablist">
                        <li 
                            v-for="(tab, tabind) in tabs"
                            :key="'tab-button-' + tab.id" 
                            role="presentation"
                            class="d-flex justify-content-center align-items-start">
                            <div 
                                class="rules-tab-button d-flex align-items-center" 
                                :class="tab.id == 1 ? 'active' : ''"
                                @click="setActiveTab(tab.id)"
                                data-bs-toggle="pill"
                                :data-bs-target="'#pills-' + tab.id"
                                role="tab"
                                aria-selected="true"
                            >
                                <div class="rules-tab-button-content d-flex flex-column">
                                    <div class="d-flex justify-content-center">
                                        <div class="rules-img text-center">
                                            <!-- tab.imgActive -->
                                            <img 
                                                 
                                                :src="tab.id == activeTab ? tab.imgActive : tab.img"
                                                class="img-fluid" 
                                                alt="szabályok"
                                                style="cursor:pointer;"
                                            >
                                            <div class="rule-main-step-img" :class="`rule-main-step-img--${tab.id}`"></div>
                                            <div class="text-center fw-700 fs-22 text-primary mt-4" :style="tab.id == activeTab ? '' : 'opacity:0.4'">{{tab.name}}</div>
                                        </div>                                        
                                    </div>                                   
                                </div>
                                
                            </div>
                            <img v-if="tabind < tabs.length - 1" src="@/assets/imgs/step-sep.svg" class="mx-4" style="margin-top:40px"/>
                        </li>
                    </ul>
                    <!-- desctop -->
                    <div 
                        class="tab-content flex-1 d-none d-sm-block mt-5" 
                        id="pills-tabContent" data-bs-ride="carousel">                        
                            <div
                                v-for="tab in tabs"
                                :key="'tab-content-' + tab.id" 
                                class="tab-pane fade"
                                :class="tab.id == 1 ? 'show active' : ''" 
                                :id="'pills-' + tab.id" 
                                role="tabpanel" 
                                aria-labelledby="'pills-'+ tab.id + '-tab'"
                            >
                                <div 
                                    class="rules-tab-content d-flex align-items-center"
                                    :class="content[2] == '1.' || content[2] == '2.' || content[2] == '4.' || content[2] == '5.' || content[2] == '7.' ? 'add-border w-100':''"
                                    v-for="(content, i) in tab.content" :key="i"
                                >  
                                    <p class="font-primary m-0 text-secondary me-3 fw-700 fs-30 fs-md-60">
                                        <!-- <img :src="'imgs/'+content[0]" width="45" height="45"> -->
                                        {{content[2]}}
                                    </p>
                                    <p class="text-secondary py-3 m-0" 
                                        v-html="content[1]"
                                        ></p>
                                </div>
                        </div>
                    </div>

                    <!-- MOBILE -->
                    <div id="gameRuleCarousel" class="carousel slide d-block d-sm-none" data-bs-ride="carousel">                        
                        <div class="carousel-inner" >
                            <div class="carousel-item" data-bs-interval="8000"
                                :class="tab.id == 1 ? 'active' : ''"
                                v-for="tab in tabs"
                                :key="tab.id" >
                                <div class="rules-img rules-img--mobile text-center">
                                    <img      
                                        :src="tab.img"
                                        class="img-fluid" 
                                        alt="szabályok"
                                        style="cursor:pointer;"
                                    >
                                    <div class="rule-main-step-img" :class="`rule-main-step-img--${tab.id}`"></div>
                                    <div class="text-center fw-700 fs-22 text-primary mt-4 mb-4">{{tab.name}}</div>
                                </div>
                                
                                <div class="rules-tab-content d-flex align-items-center justify-content-center font-secondary mb-4"
                                        v-for="(content, i) in tab.content" :key="i">
                                    <div class="d-flex justify-content-center flex-column align-items-center align-items-md-start"
                                        :class="content[2] == '1.' || content[2] == '2.' || content[2] == '4.' || content[2] == '5.' || content[2] == '7.' ? 'add-border w-100':''"
                                    >  
                                        <p class="font-main m-0 text-secondary me-3 pb-4 fw-700 fs-30 fs-md-60">
                                            <!-- <img :src="'imgs/'+content[0]" width="40" height="40"> -->
                                            {{content[2]}}
                                        </p>
                                        <p class="text-secondary pb-4 m-0" 
                                            v-html="content[1]" 
                                            
                                        ></p>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button class="carousel-control-prev" type="button" data-bs-target="#gameRuleCarousel" data-bs-slide="prev">
                            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span class="visually-hidden">Previous</span>
                        </button>
                        <button class="carousel-control-next" type="button" data-bs-target="#gameRuleCarousel" data-bs-slide="next">
                            <span class="carousel-control-next-icon" aria-hidden="true"></span>
                            <span class="visually-hidden">Next</span>
                        </button>
                    </div>
                </div>         

                <!-- BUTTONS      -->
                <div class="col-12 flex-wrap flex-md-nowrap justify-content-center d-flex py-7">
                    <button
                        class="btn btn-primary mb-4 mb-md-0 me-0 me-md-4"
                        v-scroll-to="{el:!$store.state.user ? '#regisztracio' : '#palyazat',  offset: -80}"
                        
                    >
                        Kódfeltöltés
                    </button>
                    <a class="btn btn-primary--outline " :href="$store.state.file_url" target="_blank">
                        Játékszabályzat
                    </a> 
                </div>  
                <div class="col-12 text-center">
                    <div class="text-secondary good-luck fw-700 fs-24">Sok szerencsét kívánunk a nyereményjátékhoz!</div>
                </div>
                
            </div> <!-- end of ROW -->
        </div>        
    </div>
</template>

<script>
export default {
    data () {
        return {
            
            activeTab: 1,
             tabs: [ 
                {
                    id: 1,
                    name: "Vásároljon!",
                    img: "imgs/vasarolj.svg",
                    imgActive: "imgs/vasarolj-active.svg",
                    content:[
                        ["step-1.svg",`Vásároljon <span class='fw-bold'> legalább 1 db</span> a promócióban részt vevő <a href="#termekek" style="font-weight:bold;text-decoration:underline" class='text-primary'>125g-os Toffifee terméket</a>, hogy részt vehessen a játékban.`, "1."],
                        ["step-2.svg",`A nyereményjáték időtartama: <span class='fw-bold d-block d-lg-inline-block'><b>2024. április 15. – június 2.</b></span>`, "2."],
                        [
                            "step-3.svg","<b>FIGYELEM!</b> A játékban nem lehetséges részt venni olyan bizonylattal, amelyen a Toffifee termék vásárlása nem egyértelműen megállapítható, és amelynek megnevezése nem pénztárgép által rányomtatva szerepel (pl. gyűjtőblokkok)!", "3."
                        ],
                    ]
                   

                },
                {
                    id: 2,
                    name: "Regisztráljon!",
                    img: "imgs/regisztralj.svg",
                    imgActive: "imgs/regisztralj-active.svg",
                    content: [
                        [
                            "step-4.svg",`<b><a href="#regisztracio" style="font-weight:bold;text-decoration:underline" class='text-primary'>Regisztráljon</a>, majd töltse fel</b> a vásárlást igazoló tételes bizonylat adatait és játsszon a nyereményekért!`, "4."
                        ],
                        [
                            "step-5.svg","A <strong>vásárlást igazoló eredeti bizonylat</strong> sértetlen állapotban történő megőrzése <strong style='white-space:nowrap'>2024. július 31-ig szükséges</strong>.", "5."
                        ],
                        [
                            "step-6.svg","Ne feledje, <strong>minél többször vásárol</strong> a promócióban részt vevő termékekből egyidejűleg legalább 1 darabot, <strong>annyiszor több esélye van a nyerésre!</strong>", "6."
                        ],                        
                    ]
                    
                },
                {
                    id: 3,
                    name: "Sorsolás!",
                    img: "imgs/nyerj.svg",
                    imgActive: "imgs/nyerj-active.svg",
                    content: [
                       
                        [
                            "step-6.svg","A játék ideje alatt előre meghatározott <strong>nyerőidőpontokban</strong> megnyerhető <strong> hetente 3 db családi belépő</strong> (két felnőtt és két 18 éven aluli gyermek) <strong> a Fővárosi Állat- és Növénykertbe.</strong>", "7."
                        ],
                        [
                            "step-7.svg","<strong>A fődíj  1 db buszos osztálykirándulás igénybevételére jogosító utalvány egy, a nyertes által választott osztály részére a Fővárosi Állat - és Növénykertbe.</strong><br>További részletek a <a id='jszlink' href='' target='_blank' class='text-primary jszlink'>Játékszabályzat</a> 7-8. pontjaiban. A játék lezárását követően <strong>a fődíj sorsolása: 2024. június 5. 14 óra.</strong>", "8."
                        ]
                    ]
                    
                }               
            ]
        }
    },    
    mounted(){                

        //setTimeout(() => {
            const a = document.querySelectorAll(".jszlink");
            a.forEach((item) => {
                //item.setAttribute('href', this.$store.state.file_url);                 
                item.setAttribute('href', this.$store.state.file_url);                 
            });    
        //}, 3000);
               
    },
    methods:{
        setGtm(btnName){
            this.GTtrackGA4({
                'event' : 'event',
                'category': 'jatekleiras',
                'action': 'gomb_kattintas',
                'label': btnName,                
                'clicked_text':btnName,
                'action_type': 'gomb_kattintas'
            })
        },
        setActiveTab(index){
            this.activeTab = index;
            if(index == 3){
                let a = document.getElementById('jszlink');
                a.href = this.$store.state.file_url;
            }
        }
    }
}
</script>